/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import ptBr from 'date-fns/locale/pt-BR';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ReactDOM from 'react-dom';
import axios from 'axios';

import { setupAxios } from './redux';
import store, { persistor } from './redux/store';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './app/App';
import './index.scss'; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import './_metronic/_assets/plugins/keenthemes-icons/font/ki.css';
import 'socicon/css/socicon.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './_metronic/_assets/plugins/flaticon/flaticon.css';
import './_metronic/_assets/plugins/flaticon2/flaticon.css';
// Datepicker
import 'react-datepicker/dist/react-datepicker.css';
import { MetronicLayoutProvider, MetronicSplashScreenProvider } from './_metronic/layout';
import { MetronicI18nProvider } from './_metronic/i18n';

registerLocale('ptBr', ptBr);
setDefaultLocale(ptBr);

if (process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_SENTRY_DSN !== '') {
  const replay = new Sentry.Replay({
    maskAllText: false,
    blockAllMedia: false,
    networkDetailAllowUrls: [window.location.origin, 'api.spalla.io', 'console.spalla.io']
  });
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_RELEASE || '',
    environment: process.env.REACT_APP_ENVIRONMENT || '',
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new BrowserTracing(), replay],
    tracesSampleRate: 1.0
  });
}

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios, store);

ReactDOM.render(
  <MetronicI18nProvider>
    <MetronicLayoutProvider>
      <MetronicSplashScreenProvider>
        <App store={store} persistor={persistor} basename={PUBLIC_URL} />
      </MetronicSplashScreenProvider>
    </MetronicLayoutProvider>
  </MetronicI18nProvider>,
  document.getElementById('root')
);
