import React, { useEffect } from 'react';
import { Alert, Card, Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import Player from '@app/components/player/THEOplayerWrapper';
import Toolbar from '@app/components/Toolbar';
import * as actions from '../modules/Support/_redux/atendimentosActions';

export const PreviewPage = () => {
  const dispatch = useDispatch();
  const { liveId, signalId } = useParams();
  const live = useSelector(state => state.atendimento.liveDetails);
  const source = {
    sources: [
      {
        src: `${process.env.REACT_APP_PREVIEW_URL}live/${liveId}/s${signalId}/playlist-${signalId === '1' ? 'a' : 'b'}.m3u8`,
        type: 'application/x-mpegurl'
      }
    ]
  };

  useEffect(() => {
    dispatch(actions.fetchLiveInfo(liveId));
  }, [dispatch, liveId]);

  return (
    <>
      <div className="application">
        <Helmet>
          <title>Preview do Sinal | {process.env.REACT_APP_SPALLA_NAME}</title>
        </Helmet>
      </div>
      <Container>
        <Toolbar goBackLink={`/streaming/${liveId}/panel`} title={live.nome} />
        <Alert className="mt-5" variant="primary">
          <span>
            Veja abaixo o <b>preview</b> da sua transmissão, lembrando que itens como <b>banners</b>, <b>contadores</b> e <b>pausar</b> não se aplicam
            a este <b>preview</b>.
          </span>
        </Alert>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title>Preview da transmissão | {live.nome}</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className={'PlayerDiv'}>
                  <Player source={source} onPlay={() => console.log('The player has started playing.')} />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
