import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { isPast } from 'date-fns';
import AuthWithZoom from './AuthWithZoom';
import CancelIntegrationButton from './CancelIntegrationButton';

const ZoomLogoutButton = forwardRef(({ isDeauthorized, zoomQuery }, ref) => {
  const { data, isLoading } = zoomQuery;
  const logoutModalRef = useRef(null);
  const tokenExpired = !!data && isPast(data?.tokenExpiresIn);
  const [canSignin, setCanSignin] = useState(isDeauthorized || tokenExpired);
  const onSignInWithZoomHandler = () => {
    logoutModalRef.current.openModal();
  };
  useImperativeHandle(
    ref,
    () => ({
      updateState: value => {
        setCanSignin(value);
      }
    }),
    []
  );
  const onConfirmLogoutModal = () => {
    logoutModalRef.current.closeModal();
    window.open('https://marketplace.zoom.us/user/installed', '_blank');
  };
  return (
    <>
      <CancelIntegrationButton disabled={isLoading || canSignin} onClick={onSignInWithZoomHandler} />
      <AuthWithZoom
        ref={logoutModalRef}
        buttonConfirmTitle="Desfazer integração"
        title="Desfazer integração Zoom"
        description={`Ao desfazer a integração com o Zoom, os conteúdos transferidos anteriormente para a biblioteca do ${process.env.REACT_APP_SPALLA_NAME} não serão perdidos.`}
        onConfirm={onConfirmLogoutModal}
      />
    </>
  );
});

export default ZoomLogoutButton;
